import React, { useState } from 'react';

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="flex flex-col items-center justify-center">

      <div className="w-full relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        )}

        <iframe
          plausible-embed
          src="https://plausible.io/share/th-images-viewer.omagad.tech?auth=5DD92ZKLqrs31mRPJknGV&embed=true&theme=light"
          scrolling="no"
          frameBorder="0"
          loading="lazy"
          className="w-full h-[2200px]"
          title="Plausible Analytics"
          onLoad={() => setIsLoading(false)}
        />
        <script async src="https://plausible.io/js/embed.host.js"></script>
      </div>
    </div>
  );
};

export default AdminDashboard;