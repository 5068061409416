import React from 'react';
import LazyImage from './LazyImage';
import { BASE_URL_IMAGE_VIEWER } from '../config';

const Picture = ({ pictureurl, className, lazy = false }) => {
  const url = pictureurl.startsWith('https://') ? pictureurl : `${BASE_URL_IMAGE_VIEWER}${pictureurl}`

  if (lazy) {
    return <LazyImage
      src={url}
      alt={`Photo ${pictureurl}`}
      className={`${className ? className : 'object-cover w-full h-full'}`}
    />
  }

  return <img
    src={url}
    alt={`Photo ${pictureurl}`}
    className={`${className ? className : 'object-cover w-full h-full'}`}
    loading="lazy"
  />
};

export default Picture;
