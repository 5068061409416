import React, { useState, useEffect, useCallback } from 'react';
import { API_BASE_URL } from '../config';
import Select from 'react-select';
import DuplicatesTable from '../components/DuplicatesTable';
import DuplicatesModal from '../components/DuplicatesModal';

const AdminDuplicates = ({ fetchWithAuth, selectedProject }) => {
  const [duplicates, setDuplicates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDuplicates, setUserDuplicates] = useState([]);

  const fetchDuplicates = useCallback(async () => {
    const response = await fetchWithAuth(
      `${API_BASE_URL}/api/settings/duplicates/project/${selectedProject}`
    );
    const data = await response.json();
    setDuplicates(data);
  }, [selectedProject]);

  const fetchUserDuplicates = useCallback(async (username) => {
    const response = await fetchWithAuth(
      `${API_BASE_URL}/api/settings/duplicates/project/${selectedProject}/username/${encodeURIComponent(username)}`
    );
    const data = await response.json();
    setUserDuplicates(data);
  }, [selectedProject]);

  useEffect(() => {
    if (!selectedProject) return;
    fetchDuplicates();
  }, [selectedProject]);

  const handleUserClick = async (user) => {
    setSelectedUser(user);
    await fetchUserDuplicates(user.username);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
    setUserDuplicates([]);
  };

  return (
    <div className="container mx-auto">
      {!selectedProject ? (
        <div className="text-center py-12 px-4 max-w-3xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-800 mb-8">Duplicates Detector™</h2>

          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-blue-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Analyse par Utilisateur</h3>
              <p className="text-gray-600">
                Visualisez le nombre de photos en double par utilisateur et accédez au détail
                des images concernées pour chaque utilisateur.
              </p>
            </div>

            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-green-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Visualisation des Doublons</h3>
              <p className="text-gray-600">
                Examinez les photos en double dans une interface similaire à la galerie pour
                faciliter leur identification et leur gestion.
              </p>
            </div>
          </div>

          <p className="text-gray-500 italic">
            Sélectionnez un projet dans la liste déroulante ci-dessus pour commencer l'analyse des doublons
          </p>
        </div>
      ) : duplicates.length === 0 ? (
        <div className="text-center py-12 px-4 max-w-3xl mx-auto">
          <div className="mb-8">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Aucun doublon détecté</h2>
          <p className="text-gray-600 mb-8">
            Aucune photo en double n'a été trouvée dans ce projet.
            Les photos sont considérées comme des doublons lorsqu'elles partagent le même CRC et son postées dans deux formulaires différents.
          </p>
          <div className="p-4 bg-blue-50 rounded-lg inline-block">
            <p className="text-blue-800 text-sm">
              Le système vérifie automatiquement la présence de nouveaux doublons toutes les heures.
            </p>
          </div>
        </div>
      ) : (
        <DuplicatesTable
          duplicates={duplicates}
          onUserClick={handleUserClick}
        />
      )}

      {isModalOpen && (
        <DuplicatesModal
          isOpen={true}
          onClose={handleModalClose}
          username={selectedUser.username}
          duplicates={userDuplicates}
        />
      )}
    </div>
  );
};

export default AdminDuplicates;
