import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import QuestionsSettings from './QuestionsSettings';
import SectorSettings from './SectorSettings';
import AdminDashboard from './AdminDashboard';
import AdminDuplicates from './AdminDuplicates';
import GlobalStatistics from '../components/GlobalStatistics';

import { API_BASE_URL } from '../config';
import Select from 'react-select';

const AdminPage = ({ fetchWithAuth, currentUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');

  const fetchProjects = useCallback(async () => {
    try {
      const response = await fetchWithAuth(`${API_BASE_URL}/api/columns/project`);
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  }, [fetchWithAuth]);

  useEffect(() => {
    if (!currentUser.isadmin) {
      navigate('/');
    } else {
      fetchProjects();
      if (location.pathname === '/admin') {
        navigate('/admin/statistics');
      }
    }
  }, [currentUser.isadmin, navigate, fetchProjects, location.pathname]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  if (!currentUser.isadmin) return null;

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen">
      <div className="w-200 bg-gray-100 border-r border-gray-200 p-4">
        <nav>
          <ul className="space-y-4">
            <li>
              <Link
                to="/gallery"
                className="flex items-center py-2 px-4 text-gray-700 hover:bg-gray-200 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                Retour à Gallery
              </Link>
            </li>

            <li>
              <div className="border-t border-gray-200 my-4"></div>
            </li>

            <div className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-2">
              Général
            </div>

            <li>
              <Link
                to="/admin/statistics"
                className={`block py-2 px-4 rounded transition-colors ${
                  isActive('/admin/statistics')
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-gray-200'
                }`}
              >
                Statistiques
              </Link>
            </li>
            <li>
              <Link
                to="/admin/analytics"
                className={`block py-2 px-4 rounded transition-colors ${
                  isActive('/admin/analytics')
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-gray-200'
                }`}
              >
                Traffic & usage
              </Link>
            </li>

            <li>
              <div className="border-t border-gray-200 my-4"></div>
            </li>

            <div className="text-sm font-medium text-gray-500 uppercase tracking-wider mb-2">
              Par projet
            </div>

            <li>
              <Link
                to="/admin/questions"
                className={`block py-2 px-4 rounded transition-colors ${
                  isActive('/admin/questions')
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-gray-200'
                }`}
              >
                Paramètres des questions
              </Link>
            </li>
            <li>
              <Link
                to="/admin/sectors"
                className={`block py-2 px-4 rounded transition-colors ${
                  isActive('/admin/sectors')
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-gray-200'
                }`}
              >
                Paramètres des secteurs
              </Link>
            </li>
            <li>
              <Link
                to="/admin/duplicates"
                className={`block py-2 px-4 rounded transition-colors ${
                  isActive('/admin/duplicates')
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-gray-200'
                }`}
              >
                Duplicates Detector™
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="flex-1">
        {(!isActive('/admin/analytics') && !isActive('/admin/statistics')) && (
          <div className="border-b border-gray-200 bg-white p-4">
            <div className="flex justify-between items-center">

              <Select
                value={projects?.find(p => p.value === selectedProject)}
                onChange={(option) => setSelectedProject(option?.value || '')}
                options={projects}
                isClearable
                placeholder="Sélectionner un projet"
                className="text-sm w-[300px]"
                classNamePrefix="select"
              />

              {selectedProject && (
                <>
                  {location.pathname === '/admin/questions' && (
                    <button
                      onClick={() => navigate('/admin/questions', { state: { openModal: true } })}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      Ajouter une question
                    </button>
                  )}
                  {location.pathname === '/admin/sectors' && (
                    <button
                      onClick={() => navigate('/admin/sectors', { state: { openModal: true } })}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      Ajouter un secteur
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div className="p-6">
          <Routes>
            <Route path="/statistics" element={<GlobalStatistics fetchWithAuth={fetchWithAuth} />} />
            <Route path="/analytics" element={<AdminDashboard />} />
            <Route
              path="/questions"
              element={
                <QuestionsSettings
                  fetchWithAuth={fetchWithAuth}
                  currentUser={currentUser}
                  selectedProject={selectedProject}
                />
              }
            />
            <Route
              path="/sectors"
              element={
                <SectorSettings
                  fetchWithAuth={fetchWithAuth}
                  currentUser={currentUser}
                  selectedProject={selectedProject}
                />
              }
            />
            <Route
              path="/duplicates"
              element={
                <AdminDuplicates
                  fetchWithAuth={fetchWithAuth}
                  selectedProject={selectedProject}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;