import React, { useEffect, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import { format, startOfYear, endOfYear, parseISO, differenceInDays, subYears } from 'date-fns';
import { fr } from 'date-fns/locale';
import { API_BASE_URL } from '../config';
import '../index.css';

export default function HeatmapModal({ onClose, filters, fetchWithAuth }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState('');

    // Add escape key handler
    useEffect(() => {
      const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      document.addEventListener('keydown', handleEscapeKey);

      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }, []);

  useEffect(() => {
    const fetchHeatmapData = async () => {
      const params = new URLSearchParams();

      console.log('filters', filters, params);

      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'createdat' && value) {
          params.append('createdat[startDate]', value.startDate);
          params.append('createdat[endDate]', value.endDate);
        } else if (key === 'favorite') {
          if (value === true) {
            params.append('favorite', 'true');
          }
        } else if (key === 'project') {
          for (const project of value) {
            params.append('project', project?.value);
          }
        } else if (value?.value) {
          params.append(key, value.value);
        }
      });

      try {
        const response = await fetchWithAuth(`${API_BASE_URL}/api/pictures/heatmap?${params.toString()}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const heatmapData = await response.json();

        const values = Object.entries(heatmapData).map(([date, count]) => ({
          date,
          count
        }));

        setData(values);
      } catch (error) {
        console.error('Error fetching heatmap data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHeatmapData();
  }, [filters, fetchWithAuth]);

  let startDate = startOfYear(new Date());
  let endDate = endOfYear(new Date());

  if (filters.createdat) {
    startDate = filters.createdat.startDate;
    endDate = filters.createdat.endDate;
  }

  // if range is less than 1 year, set startDate to 1 year before endDate
  if (differenceInDays(endDate, startDate) < 365) {
    startDate = subYears(endDate, 1);
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
      <div
        className="bg-white rounded-lg p-6 max-w-4xl w-[90%] mx-4 md:h-auto overflow-y-auto"
        style={{ maxHeight: 'calc(100svh - 2rem)' }}
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4 sticky top-0 bg-white pb-4 z-10">
          <h2 className="text-lg font-medium text-gray-900">
            Fréquence des photos
          </h2>
          <button className="text-gray-400 hover:text-gray-500" onClick={onClose}>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {loading ? (
          <div className="animate-pulse h-32 bg-gray-100 rounded"></div>
        ) : (
          <div className="flex flex-col">
            <div className="flex-grow">
              <div className="overflow-hidden">
                <CalendarHeatmap
                  gutterSize={1}
                  startDate={startDate}
                  endDate={endDate}
                  values={data}
                  horizontal={window.innerWidth >= 768}
                  classForValue={(value) => {
                    if (!value || value.count === 0) return 'color-empty';
                    if (value.count <= 2) return 'color-scale-1';
                    if (value.count <= 5) return 'color-scale-2';
                    if (value.count <= 10) return 'color-scale-3';
                    return 'color-scale-4';
                  }}
                  onMouseOver={(event, value) => {
                    setDetails(!value ? 'Pas de photos' : `${value.count} photo${value.count > 1 ? 's' : ''} le ${format(parseISO(value.date), 'dd MMMM yyyy', { locale: fr })}`);
                  }}
                  showWeekdayLabels={true}
                  weekdayLabels={['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']}
                />
              </div>
            </div>

            <div className="flex items-center justify-start gap-2 text-sm text-gray-500">
              <span>{details}</span>
            </div>

            <div className="flex items-center justify-end gap-2 text-sm text-gray-500 sticky bottom-0 bg-white pt-4 z-10">
              <span>Moins</span>
              <div className="flex gap-1">
                <div className="w-3 h-3 bg-[#ebedf0]"></div>
                <div className="w-3 h-3 bg-[#9be9a8]"></div>
                <div className="w-3 h-3 bg-[#40c463]"></div>
                <div className="w-3 h-3 bg-[#30a14e]"></div>
                <div className="w-3 h-3 bg-[#216e39]"></div>
              </div>
              <span>Plus</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}