import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Plausible from 'plausible-tracker'

import Home from './pages/Home';
import Gallery from './pages/Gallery';
import AdminPage from './pages/AdminPage';

import { API_BASE_URL } from './config';

const THEMES_CONFIG = {
  xiaomi: {
    logo: '/logos/SMM_NOIR.png',
    logo_client: '/logo_xiaomi.png',
    color: 'bg-white border-b border-gray-200 shadow-md',
  },
  samsung: {
    logo: '/logos/INFLUENCE_BLANC.png',
    logo_client: '/logo_samsung.png',
    color: 'bg-gray-800 text-white',
  },
  google_hardware: {
    logo: '/logos/INFLUENCE_NOIR.png',
    logo_client: '/logo_google.png',
    color: 'bg-white border-b border-gray-200 shadow-md',
  },
  hisense: {
    logo: '/logos/TECHSELL.png',
    logo_client: '/logo_hisense.jpg',
    color: 'bg-white border-b border-gray-200 shadow-md text-black',
  },
  lg: {
    logo: '/logos/TECHSELL.png',
    logo_client: '/logo_lg.svg',
    color: 'bg-[#f0ece4] border-b border-gray-200 shadow-md text-black',
  },
  dyson: {
    logo: '/logos/TECHSELL_BLANC.png',
    logo_client: '/logo_dyson.svg',
    color: 'bg-black border-b border-gray-200 shadow-md text-white',
  },
  primerimpacto: {
    logo: '/logo_primer_impacto.png',
    color: 'bg-white border-b border-gray-200 shadow-md text-red-500',
  },
  default: {
    logo: '/globe_groupe_logo.svg',
    logo_client: null,
    color: 'bg-white border-b border-gray-200 shadow-md',
  }
}

const { trackPageview, trackEvent } = Plausible({
  // Track localhost by default
  trackLocalhost: false,
  domain: process.env.PLAUSIBLE_DOMAIN || 'th-images-viewer.omagad.tech',
})

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loginError, setLoginError] = useState('');

  const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem('authToken');
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 401 || response.status === 403) {
      if (!options.noLogOut) {
        setIsAuthenticated(false);
        setCurrentUser(null);
        localStorage.removeItem('authToken');
      }
      throw new Error('Authentication failed');
    }

    return response;
  };

  const login = async (username, password) => {
    try {
      const token = btoa(`${username}:${password}`);

      // Test the credentials with /api/me
      const response = await fetch(`${API_BASE_URL}/api/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const userData = await response.json();
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
        setCurrentUser(userData);
        setLoginError('');
      } else {
        setLoginError('Identifiants incorrects');
        localStorage.removeItem('authToken');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('Connexion impossible');
      localStorage.removeItem('authToken');
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  // Check authentication status and fetch user data on mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetchWithAuth(`${API_BASE_URL}/api/me`);

        if (!response.ok) {
          throw new Error('Authentication failed');
        }

        const userData = await response.json();
        setCurrentUser(userData);
        setIsAuthenticated(true);

        // console.log('>>> userData', userData?.username);

        trackPageview({
          props: {
            user: userData?.username
          }
        });

        trackEvent('access', {
          props: {
            user: userData?.username
          }
        });

      } catch (error) {
        console.error('Auth check failed:', error);
        logout();
      }

      setTimeout(() => {
        setIsAuthenticating(false);
      }, 250);

    };

    if (!localStorage.getItem('authToken')) {
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      trackPageview();
      return;
    }

    checkAuth();
  }, []);

  if (isAuthenticating) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          <div className="text-xl font-medium text-gray-700">Authentification...</div>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-6">Identification</h1>
        <form onSubmit={(e) => {
          e.preventDefault();
          const username = e.target.username.value;
          const password = e.target.password.value;
          login(username, password);
        }}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom d'utilisateur
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Nom d'utilisateur"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Mot de passe
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Mot de passe"
            />
          </div>
          {loginError && (
            <div className="mb-4 text-red-500 text-sm">
              {loginError}
            </div>
          )}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Se connecter
          </button>
        </form>
      </div>
    );
  }

  const theme = currentUser?.themeconfig?.theme || 'default';
  const themeConfig = THEMES_CONFIG[theme] || THEMES_CONFIG['default'];

  return (
    <Router>
      <div>
        {/* Navigation */}
        <nav className={`p-4 shadow-md ${themeConfig.color}`}>
          <div className="container mx-auto flex justify-between items-center max-w-7xl">
            <div className="flex items-center space-x-4">

              <img src={themeConfig.logo} alt="Logo" className="h-14" />

              {themeConfig.logo_client && (
                <img src={themeConfig.logo_client} alt="Client Logo" className="h-14" />
              )}

            </div>

            <div className={`flex items-center space-x-4`}>
              <span>{currentUser?.username?.charAt(0).toUpperCase() + currentUser?.username?.slice(1)}</span>
              {currentUser?.isadmin && (
                <Link to="/admin" className="hidden md:block hover:text-gray-300">
                  <span className="bg-red-500 text-white px-2 py-1 rounded-full">Admin</span>
                </Link>
              )}
              <button
                onClick={logout}
                className="hover:text-gray-300"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                </svg>
              </button>
            </div>
          </div>
        </nav>

        {/* Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <Home
                fetchWithAuth={fetchWithAuth}
                currentUser={currentUser}
              />
            }
          />
          <Route
            path="/gallery"
            element={
              <Gallery
                fetchWithAuth={fetchWithAuth}
                currentUser={currentUser}
                trackEvent={trackEvent}
              />
            }
          />

          <Route path="/admin/*" element={<AdminPage fetchWithAuth={fetchWithAuth} currentUser={currentUser} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;