import React, { useState, useEffect, useCallback } from 'react';
import QuestionSettingsTable from '../components/QuestionSettingsTable';
import QuestionSettingsModal from '../components/QuestionSettingsModal';
import { API_BASE_URL } from '../config';
import { useLocation } from 'react-router-dom';

const QuestionsSettings = ({ fetchWithAuth, selectedProject }) => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [notification, setNotification] = useState({ message: '', visible: false });

  const fetchQuestionsSettings = useCallback(async () => {
    const response = await fetchWithAuth(`${API_BASE_URL}/api/settings/questions/project/${selectedProject}`);
    const data = await response.json();
    setQuestions(data);
  }, [selectedProject]);

  useEffect(() => {
    if (!selectedProject) return;
    fetchQuestionsSettings();
  }, [selectedProject]);

  useEffect(() => {
    if (location.state?.openModal) {
      setIsModalOpen(true);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleEdit = (question) => {
    setEditingQuestion(question);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetchWithAuth(`${API_BASE_URL}/api/settings/questions/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      setNotification({ message: `Updated ${data.updated_rows} picture(s)!`, visible: true });
      setTimeout(() => setNotification({ message: '', visible: false }), 3000);
    } catch (error) {
      console.error('Error deleting question:', error);
    } finally {
      fetchQuestionsSettings();
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditingQuestion(null);
  };

  const handleModalSave = async (data) => {
    try {
      let response;
      if (editingQuestion) {
        response = await fetchWithAuth(`${API_BASE_URL}/api/settings/questions/${editingQuestion.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data, projectid: selectedProject }),
        });
      } else {
        response = await fetchWithAuth(`${API_BASE_URL}/api/settings/questions`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data, projectid: selectedProject }),
        });
      }
      const result = await response.json();
      setNotification({ message: `Updated ${result.updated_rows} picture(s)!`, visible: true });
      setTimeout(() => setNotification({ message: '', visible: false }), 3000);
      handleModalClose();
    } catch (error) {
      console.error('Error saving question:', error);
    } finally {
      fetchQuestionsSettings();
    }
  };

  return (
    <div className="container mx-auto p-4">
      {notification.visible && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded shadow-lg">
          {notification.message}
        </div>
      )}

      {!selectedProject ? (
        <div className="text-center py-12 px-4 max-w-3xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-800 mb-8">Gestion des Questions</h2>

          <div className="grid md:grid-cols-3 gap-8 mb-8">
            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-blue-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Traduction</h3>
              <p className="text-gray-600">
                Rendez les questions plus lisibles et explicites pour les clients en proposant des traductions claires
              </p>
            </div>

            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-green-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Regroupement</h3>
              <p className="text-gray-600">
                Regroupez les questions similaires (comme "photo 1", "photo 2") sous un même libellé explicite
              </p>
            </div>

            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-purple-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Questions Administrateur</h3>
              <p className="text-gray-600">
                Masquez les photos opérationnelles ou internes aux clients tout en les gardant visibles pour les administrateurs
              </p>
            </div>
          </div>

          <p className="text-gray-500 italic">
            Sélectionnez un projet dans la liste déroulante ci-dessus pour commencer à gérer ses questions
          </p>
        </div>
      ) : (
        <QuestionSettingsTable
          questions={questions}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {isModalOpen && (
        <QuestionSettingsModal
          isOpen={true}
          onClose={handleModalClose}
          onSave={handleModalSave}
          question={editingQuestion}
          questions={questions}
          selectedProject={selectedProject}
          fetchWithAuth={fetchWithAuth}
        />
      )}
    </div>
  );
};

export default QuestionsSettings;