import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { API_BASE_URL } from '../config';

const QuestionSettingsModal = ({ isOpen, onClose, onSave, question, questions, selectedProject, fetchWithAuth }) => {
  const [originalQuestions, setOriginalQuestions] = useState([]);
  const [formData, setFormData] = useState({
    question: '',
    translation: '',
    admin_only: false,
  });

  const fetchQuestions = useCallback(async () => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/columns/untranslatedquestions?project=${selectedProject}`
      );

      const values = await response.json();

      // For other columns, transform to react-select format
      setOriginalQuestions(values.map(value => ({ value, label: value })));

    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }, [fetchWithAuth, selectedProject]);

  useEffect(() => {
    // no need to fetch usernames if we're editing an existing sector
    if (question)
      return;

    fetchQuestions();
  }, [fetchQuestions]);

  const existingQuestions = questions.map(q => q.question);

  // Create options for react-select, filtering out existing questions
  const questionOptions = originalQuestions
    .filter(q => !existingQuestions.includes(q.value));

  console.log(existingQuestions);
  console.log(originalQuestions);

  useEffect(() => {
    if (question) {
      setFormData({
        question: question.question,
        translation: question.translation || '',
        admin_only: question.admin_only,
      });
    } else {
      setFormData({
        question: '',
        translation: '',
        admin_only: false,
      });
    }

    // Add event listener for Escape key
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [question, onClose]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleQuestionSelect = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      question: selectedOption.value,
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {question ? 'Edit Question' : 'New Question'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Question
            </label>
            {question ? (
              <input
                type="text"
                value={formData.question}
                className="w-full p-2 border rounded-md bg-gray-100"
                disabled
              />
            ) : (
              <Select
                value={formData.question ? { value: formData.question, label: formData.question } : null}
                onChange={handleQuestionSelect}
                options={questionOptions}
                className="w-full"
                isSearchable
                placeholder="Select a question..."
                required
              />
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Translation
            </label>
            <input
              type="text"
              name="translation"
              value={formData.translation}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="admin_only"
              checked={formData.admin_only}
              onChange={handleChange}
              className="h-4 w-4 text-blue-600 rounded"
            />
            <label className="ml-2 text-sm text-gray-700">Admin Only</label>
          </div>

          <div className="flex justify-end space-x-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuestionSettingsModal;