import React from 'react';

function Pagination({ paginationInfo, onPageChange }) {
  return (
      <div className="flex flex-col sm:flex-row items-center justify-between px-2 py-3 sm:px-6 min-w-[150px]">
        {/* Mobile version - combined count and navigation */}
        <div className="flex w-full items-center justify-between sm:hidden">
          <button
            onClick={() => onPageChange(paginationInfo.page - 1)}
            disabled={paginationInfo.page === 1}
            className={`relative inline-flex items-center rounded-md px-2 py-1 text-sm font-semibold ${
              paginationInfo.page === 1
                ? 'bg-gray-100 text-gray-400'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            }`}
          >
            <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <circle cx="12" cy="12" r="10" fill="currentColor"/>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} stroke="white" d="M15 18l-6-6 6-6"/>
            </svg>
          </button>

          {/* Centered count */}
          <span className="relative inline-flex items-center px-2 py-2 text-sm font-semibold text-gray-700 text-center">
            {paginationInfo.page} sur {paginationInfo.totalPages}
          </span>

          <button
            onClick={() => onPageChange(paginationInfo.page + 1)}
            disabled={paginationInfo.page === paginationInfo.totalPages}
            className={`relative inline-flex items-center rounded-md px-2 py-1 text-sm font-semibold ${
              paginationInfo.page === paginationInfo.totalPages
                ? 'bg-gray-100 text-gray-400'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            }`}
          >
              <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <circle cx="12" cy="12" r="10" fill="currentColor"/>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} stroke="white" d="M9 6l6 6-6 6"/>
              </svg>
          </button>
        </div>

        {/* Desktop version */}
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => onPageChange(1)}
                disabled={paginationInfo.page === 1}
                className={`cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  paginationInfo.page === 1 ? 'bg-gray-100' : 'bg-white'
                }`}
              >
                <span className="sr-only">Premier</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </button>
              <button
                onClick={() => onPageChange(paginationInfo.page - 1)}
                disabled={paginationInfo.page === 1}
                className={`cursor-pointer relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  paginationInfo.page === 1 ? 'bg-gray-100' : 'bg-white'
                }`}
              >
                <span className="sr-only">Précédent</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </button>

              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                Page {paginationInfo.page} sur {paginationInfo.totalPages}
              </span>

              <button
                onClick={() => onPageChange(paginationInfo.page + 1)}
                disabled={paginationInfo.page === paginationInfo.totalPages}
                className={`cursor-pointer relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  paginationInfo.page === paginationInfo.totalPages ? 'bg-gray-100' : 'bg-white'
                }`}
              >
                <span className="sr-only">Suivant</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </button>
              <button
                onClick={() => onPageChange(paginationInfo.totalPages)}
                disabled={paginationInfo.page === paginationInfo.totalPages}
                className={`cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  paginationInfo.page === paginationInfo.totalPages ? 'bg-gray-100' : 'bg-white'
                }`}
              >
                <span className="sr-only">Dernier</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
  );
}

export default Pagination;