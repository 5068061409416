import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../components/Pagination';
import ScrollToTop from '../components/ScrollToTop';
import Filters from '../components/Filters';
import { API_BASE_URL } from '../config';
import ImageModal from '../components/ImageModal';
import { useSearchParams } from 'react-router-dom';
import ConfigModal from '../components/ConfigModal';
import HeatmapModal from '../components/HeatmapModal';
import isEqual from 'lodash/isEqual';
import Picture from '../components/Picture';

const LoadingIndicator = () => (
  <div className="max-w-7xl mx-auto p-2 sm:p-4">
    <div className="animate-pulse">
      {/* Header skeleton */}
      <div className="mb-4">
        <div className="h-8 w-32 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 w-64 bg-gray-200 rounded"></div>
      </div>

      {/* Filters skeleton */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1.5 mb-4">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="mb-1">
            <div className="h-3 w-20 bg-gray-200 rounded mb-1"></div>
            <div className="h-[30px] bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>

      {/* Pictures grid skeleton */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="border rounded-lg overflow-hidden">
            <div className="h-48 bg-gray-200"></div>
            <div className="p-4">
              <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
              <div className="h-3 w-1/2 bg-gray-200 rounded mb-1"></div>
              <div className="h-3 w-1/3 bg-gray-200 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const createParams = (filters) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'createdat' && value) {
      params.append('createdat[startDate]', value.startDate);
      params.append('createdat[endDate]', value.endDate);
    } else if (key === 'favorite') {
      if (value === true) {
        params.append('favorite', 'true');
      }
    } else if (Array.isArray(value)) {
      // Handle array values
      value.forEach(v => {
        params.append(`${key}[]`, v.value);
      });
    } else if (value?.value) {
      params.append(key, value.value);
    }
  });

  return params;
};

function Gallery({ fetchWithAuth, currentUser, trackEvent }) {
  const isAdmin = !!currentUser?.isadmin;
  const isWildcard = currentUser?.projects?.allowed?.includes('*');
  const [searchParams, setSearchParams] = useSearchParams();
  const [showHeatmap, setShowHeatmap] = useState(false);

  // 1. First, declare constants that don't depend on anything
  let allowedColumns = [
    'client',
    'project',
    'translatedquestion',
    'item',
    'username',
    'sector',
    'shopsign',
    'shopname',
    'shopzipcode',
  ];

  // username is only available for admins
  const adminColumns = ['username'];

  if (!currentUser?.isadmin)
    allowedColumns = allowedColumns.filter(column => !adminColumns.includes(column));

  if (!isWildcard)
    allowedColumns = allowedColumns.filter(column => column !== 'client');

  // 2. Then declare functions that use those constants
  const getDefaultDateRange = () => {
    const end = new Date();
    const start = new Date();
    start.setFullYear(end.getFullYear() - 1);

    return {
      startDate: start.toISOString().split('T')[0],
      endDate: end.toISOString().split('T')[0]
    };
  };

  const getDefaultFilters = () => {
    return {
      createdat: getDefaultDateRange()
    };
  };

  const getInitialFilters = () => {
    const urlFilters = {};

    // Handle date filter
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    if (startDate && endDate) {
      urlFilters.createdat = { startDate, endDate };
    } else {
      urlFilters.createdat = getDefaultDateRange();
    }

    // Handle favorite filter
    const favorite = searchParams.get('favorite');
    if (favorite === 'true') {
      urlFilters.favorite = true;
    }

    // Handle other filters
    allowedColumns.forEach(column => {
      const value = searchParams.getAll(column);

      if (value.length > 0) {
        if (column === 'project') {
          // For project, handle as array of objects
          urlFilters[column] = value.map(v => ({ value: v, label: v }));
        } else if (['project'].includes(column)) {
          // For other multi-select fields
          urlFilters[column] = value.map(v => ({ value: v, label: v }));
        } else {
          // For single-select fields
          urlFilters[column] = { value: value[0], label: value[0] };
        }
      }
    });

    return urlFilters;
  };

  // 3. Then declare state variables
  const [pictures, setPictures] = useState([]);
  const [dimensions, setDimensions] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filters, setFilters] = useState(getInitialFilters());
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    page: 1,
    limit: 100,
    totalPages: 0
  });
  const [selectedPictures, setSelectedPictures] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [config, setConfig] = useState(() => {
    const savedConfig = localStorage.getItem('galleryConfig');
    return savedConfig ? JSON.parse(savedConfig) : { addMetadata: true };
  });

  // 4. Define fetchDimensions function
  const fetchDimensions = useCallback(async () => {
    try {
      // Create URL params from current filters
      const params = createParams(filters);

      const dimensionPromises = allowedColumns.map(async (column) => {
        const response = await fetchWithAuth(
          `${API_BASE_URL}/api/columns/${column}?${params.toString()}`
        );
        const values = await response.json();

        // For project, the API already returns the correct format
        if (column === 'project')
          return [column, values];

        // For other columns, transform to react-select format
        return [column, values.map(value => ({ value, label: value }))];
      });

      const results = await Promise.all(dimensionPromises);
      const newDimensions = Object.fromEntries(results);
      setDimensions(newDimensions);

      // Handle both single project and array of projects
      if (filters.project) {
        if (Array.isArray(filters.project)) {
          // Handle array of projects
          filters.project = filters.project.map(projectFilter => {
            const project = newDimensions.project.find(p => `${p.value}` === `${projectFilter.value}`);
            return { value: project.value, label: project.label };
          });
        } else {
          // Handle single project
          const project = newDimensions.project.find(p => `${p.value}` === `${filters.project.value}`);
          filters.project = { value: project.value, label: project.label };
        }
      }

    } catch (error) {
      console.error('Error fetching dimensions:', error);
    }
  }, [filters, allowedColumns, fetchWithAuth, loading]);

  // 7.Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'createdat' && value) {
        params.set('startDate', value.startDate);
        params.set('endDate', value.endDate);
      } else if (key === 'favorite') {
        if (value === true) {
          params.set('favorite', 'true');
        }
      } else if (Array.isArray(value)) {
        // Handle array values
        value.forEach(v => {
          params.append(key, v.value);
        });
      } else if (value?.value) {
        params.set(key, value.value);
      }
    });

    params.set('page', paginationInfo.page.toString());
    setSearchParams(params, { replace: true });
  }, [filters, paginationInfo.page, setSearchParams]);

  // Fetch dimension values for filters
  // Ensure pictures are fetched first
  useEffect(() => {
    if (initialLoading)
      return;

    fetchDimensions();
  }, [filters, initialLoading]);

  // Update fetchPictures effect
  useEffect(() => {
    const fetchPictures = async () => {
      console.log('>>> fetchPictures', filters);

      try {
        if (!initialLoading)
          setContentLoading(true);

        const params = createParams(filters);
        params.append('page', paginationInfo.page);
        params.append('limit', paginationInfo.limit);

        const response = await fetchWithAuth(`${API_BASE_URL}/api/pictures?${params}`);
        const data = await response.json();

        setPictures(data.pictures || []);
        setPaginationInfo(prev => ({
          ...prev,
          ...data.pagination
        }));

      } catch (error) {
        console.error('Error fetching pictures:', error);
      } finally {
        setContentLoading(false);
        setInitialLoading(false);
        setLoading(false);
      }
    };

    fetchPictures();
  }, [JSON.stringify(filters), paginationInfo.page, paginationInfo.limit]);

  // Update handleFilterChange to maintain URL sync
  const handleFilterChange = (key, value) => {
    const newFilters = { ...filters };

    trackEvent('filter', {
      props: {
        key,
        user: currentUser?.username,
      }
    });

    if (value === null) {
      delete newFilters[key];
    } else {
      newFilters[key] = value;
    }

    setFilters(newFilters);

    // Update URL params
    const params = new URLSearchParams(searchParams);

    if (key === 'createdat' && value) {
      params.set('startDate', value.startDate);
      params.set('endDate', value.endDate);
    } else if (key === 'project') {
      params.set(key, value?.label || value?.value); // Use the label for projects in URL
    } else if (value) {
      params.set(key, value.value || value);
    } else {
      params.delete(key);
    }

    setSearchParams(params);
  };

  // Handle ESC key press
  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setSelectedImage(null);
    }
  }, []);

  // Add/remove event listener for ESC key
  useEffect(() => {
    if (selectedImage) {
      document.addEventListener('keydown', handleEscKey);
      return () => document.removeEventListener('keydown', handleEscKey);
    }
  }, [selectedImage, handleEscKey]);

  // Add this new function inside the Gallery component
  const handlePageChange = (newPage) => {
    setPaginationInfo(prev => ({
      ...prev,
      page: newPage
    }));
  };

  const handleSelectPicture = (pictureId) => {
    setSelectedPictures(prev => {
      if (prev.includes(pictureId)) {
        return prev.filter(id => id !== pictureId);
      }
      return [...prev, pictureId];
    });
  };

  const handleSelectAll = () => {
    if (selectedPictures.length === pictures.length) {
      setSelectedPictures([]);
    } else {
      setSelectedPictures(pictures.map(p => p.id));
    }
  };

  const handleClearSelection = () => {
    setSelectedPictures([]);
  };

  const handleExportSelected = async () => {
    if (selectedPictures.length === 0) return;

    setIsExporting(true);

    trackEvent('export', {
      props: {
        count: selectedPictures.length,
        user: currentUser?.username,
      }
    });

    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${API_BASE_URL}/api/export-pictures`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pictureIds: selectedPictures,
          addMetadata: config.addMetadata
        }),
      });

      if (!response.ok) throw new Error('Export failed');

      const { downloadUrl } = await response.json();

      // Create a hidden anchor element for download
      const link = document.createElement('a');
      link.href = `${window.location.origin}/downloads/${downloadUrl}`;
      link.target = '_blank';
      link.rel = 'noopener noreferrer'; // Security best practice
      link.style.display = 'none';
      document.body.appendChild(link);

      // Trigger click and clean up
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Export error:', error);
      // You might want to add error handling UI here
    } finally {
      setIsExporting(false);
    }
  };

  // Update handleToggleFavorite to modify the picture's favorite status
  const handleToggleFavorite = useCallback(async (pictureId, favorite) => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/pictures/${pictureId}/favorite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ favorite })
        }
      );

      if (response.ok) {
        // Update the favorite status in both pictures and selectedImage
        setPictures(prev => prev.map(pic =>
          pic.id === pictureId ? { ...pic, favorite } : pic
        ));

        if (selectedImage?.id === pictureId) {
          setSelectedImage(prev => ({ ...prev, favorite }));
        }
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  }, [fetchWithAuth, selectedImage]);

  // Add a content loading indicator component
  const ContentLoadingIndicator = () => (
    <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
      <div className="animate-pulse flex flex-col items-center">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        <span className="mt-2 text-sm text-gray-600">Chargement...</span>
      </div>
    </div>
  );

  // Add reset filters function
  const handleResetFilters = useCallback(() => {
    // Reset filters to only include default date range
    setFilters({
      createdat: getDefaultDateRange()
    });

    // Reset pagination to page 1
    setPaginationInfo(prev => ({
      ...prev,
      page: 1
    }));

    // Clear URL params except for page=1
    const params = new URLSearchParams();
    params.set('page', '1');
    params.set('startDate', getDefaultDateRange().startDate);
    params.set('endDate', getDefaultDateRange().endDate);
    setSearchParams(params, { replace: true });
  }, [setSearchParams]);

  const getCurrentImageIndex = () => {
    return pictures.findIndex(p => p.id === selectedImage.id);
  };

  const handlePreviousImage = () => {
    const currentIndex = getCurrentImageIndex();
    if (currentIndex > 0) {
      setSelectedImage(pictures[currentIndex - 1]);
    }
  };

  const handleNextImage = () => {
    const currentIndex = getCurrentImageIndex();
    if (currentIndex < pictures.length - 1) {
      setSelectedImage(pictures[currentIndex + 1]);
    }
  };

  const handleDeletePicture = async (pictureId) => {
    try {
      await fetchWithAuth(
        `${API_BASE_URL}/api/pictures/${pictureId}/delete`, { method: 'POST', noLogOut: true }
      );
      // Remove the picture from the local state
      setPictures(pictures.filter(p => p.id !== pictureId));
    } catch (error) {
      console.error('Error deleting picture:', error);
      throw error;
    }
  };

  const handleConfigChange = (key, value) => {
    const newConfig = { ...config, [key]: value };
    setConfig(newConfig);
    localStorage.setItem('galleryConfig', JSON.stringify(newConfig));
  };

  const activeFiltersCount = () => {
    if (isEqual(filters, getDefaultFilters()))
      return 0;

    return Object.keys(filters).length;
  };

  if (loading)
    return <LoadingIndicator />;

  return (
    <div className="max-w-7xl mx-auto p-2 sm:p-4 relative">
      <div className="absolute top-5 right-2 text-gray-500 hover:text-gray-700 flex flex-row mr-[10px]">
        {isAdmin && (
          <button
            onClick={() => setShowHeatmap(true)}
            className="flex items-center gap-2 ml-2 text-sm text-gray-700 hover:text-gray-900 mr-2"
          >
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <rect x="2" y="2" width="20" height="20" rx="2" strokeWidth="2"/>
              <path d="M7 16V8M12 16V4M17 16v-5" strokeWidth="2" strokeLinecap="round"/>
            </svg>
            {/* <span className="hidden md:inline">Voir la fréquence</span> */}
          </button>
        )}
      <button
        onClick={() => setIsConfigOpen(true)}
        title="Configuration"
      >
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </button>
    </div>

      {showHeatmap && (
        <HeatmapModal
          onClose={() => setShowHeatmap(false)}
          filters={filters}
          fetchWithAuth={fetchWithAuth}
        />
      )}

      <ConfigModal
        isOpen={isConfigOpen}
        onClose={() => setIsConfigOpen(false)}
        config={config}
        onConfigChange={handleConfigChange}
      />

      {initialLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Header and controls */}
          <div className="flex flex-col gap-2 mb-4">
            {/* Title and counts - reduced margins */}
            <div>
              <p className="text-xs sm:text-sm text-gray-600 mt-1">
                {selectedPictures.length > 0 && `${selectedPictures.length} selectionné(s)`}
              </p>
            </div>

            {/* Controls - more compact layout */}
            <div className="flex flex-wrap gap-1 text-xs sm:text-sm min-h-[15px]">
              {activeFiltersCount() > 0 && (
                <button
                  onClick={handleSelectAll}
                  className="bg-blue-500 hover:bg-blue-700 text-white py-0.5 px-2 rounded"
              >
                  {selectedPictures.length === pictures.length ? 'Déselectionner' : 'Sélectionner visibles'}
                </button>
              )}

              {selectedPictures.length > 0 && (
                <>
                  <button
                    onClick={handleClearSelection}
                    className="bg-gray-500 hover:bg-gray-700 text-white py-0.5 px-2 rounded"
                  >
                    Effacer la sélection
                  </button>
                  <button
                    onClick={handleExportSelected}
                    disabled={isExporting}
                    className="bg-green-500 hover:bg-green-700 text-white py-0.5 px-2 rounded disabled:bg-green-300"
                  >
                    {isExporting ? 'Exportation...' : 'Exporter la sélection'}
                  </button>
                </>
              )}
            </div>
          </div>

          {/* Pagination + Filters */}
          <div className="sticky top-0 z-20 bg-white shadow-md flex flex-row justify-between border-gray-200 items-center">
            <Filters
              dimensions={dimensions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
              allowedColumns={allowedColumns}
              fetchWithAuth={fetchWithAuth}
              defaultFilters={getDefaultFilters()}
              paginationInfo={paginationInfo}
              activeFiltersCount={activeFiltersCount}
              isWildcard={isWildcard}
            />
            <Pagination
              paginationInfo={paginationInfo}
              onPageChange={handlePageChange}
              totalFilters={Object.keys(filters).length}
            />
          </div>

          {/* Content area with relative positioning for loading overlay */}
          <div className="relative pt-4">
            {/* {contentLoading && <ContentLoadingIndicator />} */}

            <div className={contentLoading ? 'opacity-50' : ''}>
              {pictures.length === 0 ? (
                /* No results message */
                <div className="flex flex-col items-center justify-center py-12 px-4">
                  <svg
                    className="w-24 h-24 text-gray-300 mb-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                  <h3 className="text-lg font-medium text-gray-900 mb-1">
                    Aucun résultat trouvé
                  </h3>
                  <p className="text-gray-500 text-center max-w-md">
                    Aucune image ne correspond aux filtres sélectionnés. Essayez de modifier vos critères de recherche.
                  </p>
                  <button
                    onClick={handleResetFilters}
                    className="mt-4 text-sm text-blue-600 hover:text-blue-800 font-medium"
                  >
                    Réinitialiser les filtres
                  </button>
                </div>
              ) : (
                /* Pictures grid */
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {pictures.map((picture) => (
                    <div
                      key={picture.id}
                      className="relative border rounded-lg overflow-hidden hover:shadow-lg transition-shadow cursor-pointer group"
                      onClick={() => setSelectedImage(picture)}
                    >
                      {/* Admin-only overlay */}
                      {picture.admin_only && (
                        <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center z-10 group/admin">
                          <div className="bg-black bg-opacity-50 rounded-full p-2 relative">
                            <svg
                              className="w-6 h-6 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                              />
                            </svg>
                            {/* Tooltip */}
                            <div className="absolute left-1/2 -translate-x-1/2 -bottom-8 hidden group-hover/admin:block bg-black text-white text-xs py-1 px-2 rounded whitespace-nowrap">
                              Non visible pour le client
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Existing project name badge */}
                      {isWildcard && (
                        <div className={`absolute top-2 left-2 z-10 bg-opacity-80 rounded-full p-1 ${
                          ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500', 'bg-pink-500', 'bg-indigo-500', 'bg-orange-500', 'bg-teal-500', 'bg-cyan-500'][
                            picture.client.charAt(0).toLowerCase().charCodeAt(0) % 10
                          ]
                        }`}>
                          <p className="text-xs text-white">{picture.client}</p>
                        </div>
                      )}

                      {/* Existing favorite heart */}
                      {picture.favorite && (
                        <div className="absolute top-2 left-2 z-10 bg-black bg-opacity-50 rounded-full p-1">
                          <svg
                            className="w-4 h-4 text-red-500 fill-current"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="0"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </div>
                      )}

                      {/* Top-right checkbox */}
                      <div
                        className="absolute top-2 right-2 z-10"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectPicture(picture.id);
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedPictures.includes(picture.id)}
                          onChange={() => {}}
                          className="h-5 w-5 rounded border-gray-300"
                        />
                      </div>

                      <Picture pictureurl={picture.pictureurl} className="w-full h-72 sm:h-80 object-cover transition-all duration-200" lazy={true} />

                      <div className="p-2 sm:p-3 absolute bottom-0 left-0 right-0 bg-white bg-opacity-90
                        group-hover:bg-opacity-100 group-hover:h-auto transition-all duration-200
                        transform group-hover:scale-105 origin-bottom">
                        <h3 className="text-sm font-semibold truncate">{picture.shopname}</h3>
                        {/* <p className="text-xs text-gray-600 truncate">{picture.shopcity}</p> */}
                        {isAdmin && <p className="text-xs text-gray-600 truncate">{picture.username}</p>}
                        {picture.sector && <p className="text-xs text-gray-600 truncate">{picture.sector}</p>}
                        <p className="text-xs text-gray-500">
                          {new Date(picture.createdat).toLocaleDateString()}
                        </p>

                        {/* Informations additionnelles qui apparaissent au survol */}
                        <div className="max-h-0 group-hover:max-h-32 overflow-hidden transition-all duration-200">
                          <div className="pt-2 border-t mt-2 border-gray-200">
                            {picture.projectname && (
                              <p className="text-xs text-gray-600">
                                <span className="font-medium">Projet:</span> {picture.projectname.includes('-') ? picture.projectname.split(' ').slice(1).join(' ').trim().replace(/^-/, '').trim() : picture.projectname}
                              </p>
                            )}
                            {picture.question && (
                              <p className="text-xs text-gray-600">
                                <span className="font-medium">Question:</span> {picture.question}
                              </p>
                            )}
                            {picture.item && (
                              <p className="text-xs text-gray-600">
                                <span className="font-medium">Item:</span> {picture.item}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Modal and ScrollToTop remain unchanged */}
          {selectedImage && (
            <ImageModal
              image={selectedImage}
              onClose={() => setSelectedImage(null)}
              onToggleFavorite={(pictureId) => handleToggleFavorite(pictureId, !selectedImage.favorite)}
              onDelete={handleDeletePicture}
              onPrevious={handlePreviousImage}
              onNext={handleNextImage}
              hasPrevious={getCurrentImageIndex() > 0}
              hasNext={getCurrentImageIndex() < pictures.length - 1}
              curentUser={currentUser}
            />
          )}
          <ScrollToTop />
        </>
      )}
    </div>
  );
}

export default Gallery;