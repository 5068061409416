import React, { useState } from 'react';
import Picture from './Picture';
// Add new DeleteConfirmationModal component
const DeleteConfirmationModal = ({ onConfirm, onCancel, isDeleting, currentUser }) => {
  const isAdmin = !!currentUser?.isadmin;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[60]" onClick={onCancel}>
      <div className="bg-white rounded-lg p-6 max-w-sm mx-4" onClick={e => e.stopPropagation()}>
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Confirmer la suppression
        </h3>
        <p className="text-sm text-gray-500 mb-4">
          Êtes-vous sûr de vouloir supprimer cette image&nbsp;? Elle sera conservée en base mais ne sera plus visible dans Gallery.
        </p>
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            onClick={onCancel}
            disabled={isDeleting}
          >
            Annuler
          </button>
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:bg-red-400"
            onClick={onConfirm}
            disabled={isDeleting}
          >
            {isDeleting ? 'Suppression...' : 'Supprimer'}
          </button>
        </div>
      </div>
    </div>
  );
};

function ImageModal({
  image,
  onClose,
  onToggleFavorite,
  onDelete,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext,
  curentUser,
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const isAdmin = !!curentUser?.isadmin;

  // Handle keyboard navigation
  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowLeft' && hasPrevious) onPrevious();
      if (e.key === 'ArrowRight' && hasNext) onNext();
      if (e.key === 'Escape') {
        if (showDeleteConfirmation) {
          setShowDeleteConfirmation(false);
        } else {
          onClose();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onPrevious, onNext, onClose, hasPrevious, hasNext, showDeleteConfirmation]);

  // Handle delete with confirmation
  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(image.id);
      onClose();
    } catch (error) {
      console.error('Failed to delete image:', error);
      alert('Failed to delete image');
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="relative w-[90vw] h-[90vh] flex items-center justify-center"
          onClick={e => e.stopPropagation()}
        >
          {/* Previous button */}
          {hasPrevious && (
            <button
              className="absolute left-4 top-1/2 -translate-y-1/2 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
              onClick={onPrevious}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          )}

          {/* Next button */}
          {hasNext && (
            <button
              className="absolute right-4 top-1/2 -translate-y-1/2 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
              onClick={onNext}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          )}

          {/* Close and Delete buttons */}
          <div className="absolute top-4 right-4 flex gap-2">
            {isAdmin && (
              <>
                <div className="bg-black bg-opacity-50 rounded-full px-4 py-2 text-white text-sm flex items-center gap-2">
                  #{image.id}
                </div>
                <button
                  className="text-white bg-red-500 bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteConfirmation(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </>
            )}
            <button
              className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Image */}
          <Picture pictureurl={image.pictureurl} className="max-w-full max-h-full object-contain" />

          {/* Image details */}
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black bg-opacity-50 text-white p-4 rounded max-w-2xl w-[calc(100%-2rem)]">
            <div className="grid grid-cols-1 gap-2">
              <div>
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold text-lg">{image.shopname}</h3>
                  {onToggleFavorite && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onToggleFavorite(image.id);
                      }}
                      className="p-1 hover:bg-gray-700 rounded-full"
                    >
                      <svg
                        className={`w-6 h-6 ${
                          image.favorite ? 'text-red-500 fill-current' : 'text-white'
                        }`}
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={image.favorite ? "0" : "2"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <p className="text-sm">{image.shopcity}</p>
                {isAdmin && <p className="text-sm">{image.username}</p>}
                <p className="text-xs mt-1">
                  {new Date(image.createdat).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </p>
              </div>

              {/* Question and Item section */}
              {(image.translatedquestion || image.item) && (
                <div className="border-t border-white/20 mt-2 pt-2">
                  {image.translatedquestion && (
                    <div className="mb-1">
                      <span className="text-gray-300 text-sm">Question: </span>
                      <span className="text-sm">{image.translatedquestion}</span>
                    </div>
                  )}
                  {image.item && (
                    <div>
                      <span className="text-gray-300 text-sm">Item: </span>
                      <span className="text-sm">{image.item}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
}

export default ImageModal;