import React, { useState, useEffect, useCallback } from 'react';
import SectorSettingsTable from '../components/SectorSettingsTable';
import SectorSettingsModal from '../components/SectorSettingsModal';
import { API_BASE_URL } from '../config';
import { useLocation } from 'react-router-dom';

const SectorSettings = ({ fetchWithAuth, selectedProject }) => {
  const location = useLocation();
  const [sectors, setSectors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingSector, setEditingSector] = useState(null);
  const [notification, setNotification] = useState({ message: '', visible: false });

  const fetchSectorSettings = useCallback(async () => {
    const response = await fetchWithAuth(`${API_BASE_URL}/api/settings/sectors/project/${selectedProject}`);
    const data = await response.json();
    setSectors(data);
  }, [selectedProject]);

  useEffect(() => {
    if (!selectedProject) return;
    fetchSectorSettings();
  }, [selectedProject]);

  useEffect(() => {
    if (location.state?.openModal) {
      setIsModalOpen(true);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleEdit = (sector) => {
    setEditingSector(sector);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetchWithAuth(`${API_BASE_URL}/api/settings/sectors/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      setNotification({ message: `Updated ${data.updated_rows} sector(s)!`, visible: true });
      setTimeout(() => setNotification({ message: '', visible: false }), 3000);
    } catch (error) {
      console.error('Error deleting sector:', error);
    } finally {
      fetchSectorSettings();
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditingSector(null);
  };

  const handleModalSave = async (data) => {
    try {
      let response;
      if (editingSector) {
        response = await fetchWithAuth(`${API_BASE_URL}/api/settings/sectors/${editingSector.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data, projectid: selectedProject }),
        });
      } else {
        response = await fetchWithAuth(`${API_BASE_URL}/api/settings/sectors`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data, projectid: selectedProject }),
        });
      }
      const result = await response.json();
      setNotification({ message: `Updated ${result.updated_rows} sector(s)!`, visible: true });
      setTimeout(() => setNotification({ message: '', visible: false }), 3000);
      handleModalClose();
    } catch (error) {
      console.error('Error saving sector:', error);
    } finally {
      fetchSectorSettings();
    }
  };

  return (
    <div className="container mx-auto p-4">
      {notification.visible && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded shadow-lg">
          {notification.message}
        </div>
      )}

      {!selectedProject ? (
        <div className="text-center py-12 px-4 max-w-3xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-800 mb-8">Gestion des Secteurs</h2>
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-blue-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Organisation Territoriale</h3>
              <p className="text-gray-600">
                Créez des secteurs géographiques pour regrouper vos utilisateurs et leurs photos par zone d'intervention.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-md">
              <div className="text-green-500 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold mb-2">Filtrage Client</h3>
              <p className="text-gray-600">
                Permettez à vos clients de visualiser les photos par zone géographique grâce à un système de filtrage intuitif.
              </p>
            </div>
          </div>
          <p className="text-gray-500 italic">
            Sélectionnez un projet dans le menu déroulant ci-dessus pour commencer la gestion des secteurs
          </p>
        </div>
      ) : (
        <SectorSettingsTable
          sectors={sectors}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}

      {isModalOpen && (
        <SectorSettingsModal
          isOpen={true}
          onClose={handleModalClose}
          onSave={handleModalSave}
          sector={editingSector}
          sectors={sectors}
          selectedProject={selectedProject}
          fetchWithAuth={fetchWithAuth}
        />
      )}
    </div>
  );
};

export default SectorSettings;