import React from 'react';
import Modal from './Modal';
import Picture from './Picture';
const DuplicatesModal = ({ isOpen, onClose, username, duplicates }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Doublons de ${username}`}
      size="xl"
    >
      <div className="p-4">
        <div className="mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            Photos en double détectées
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Les images suivantes ont été identifiées comme des doublons
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {duplicates.map((image, index) => (
            <div
              key={image.id}
              className="relative group aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden"
            >
              <Picture pictureurl={image.pictureurl} className="object-cover w-full h-full" />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-200" />
              <div className="absolute top-2 right-2 bg-black bg-opacity-80 rounded-full px-2 py-1 flex items-center justify-center">
                <span className="text-white text-xs">
                  #{image.picture_crc?.slice(-5)}
                </span>
              </div>
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent">
                <div className="text-white text-sm">
                  <div>Question: {image.question}</div>
                  {image.item && <div>Item: {image.item}</div>}
                  <div>{new Date(image.createdat).toLocaleDateString('fr-FR')}</div>
                  <div>{image.shopname}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default DuplicatesModal;