import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DateFilter from './DateFilter';
import isEqual from 'lodash/isEqual';

const i18n = {
  client: 'Client',
  shopcity: 'Ville',
  username: 'Team',
  shopcountry: 'Pays',
  sector: 'Secteur',
  shopname: 'Nom du magasin',
  shopprovince: 'Province',
  shoptype: 'Type de magasin',
  shopsign: 'Enseigne',
  project: 'Programme',
  shopzipcode: 'Code postal',
  item: 'Produit, concurrent, emplacement...',
  translatedquestion: 'Question',
};

export default function Filters({
  dimensions,
  filters,
  handleFilterChange,
  allowedColumns,
  defaultFilters,
  handleResetFilters,
  paginationInfo,
  activeFiltersCount,
  isWildcard
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isDefaultFilters = isEqual(filters, defaultFilters);

  // Add escape key handler
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && isDrawerOpen) {
        setIsDrawerOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isDrawerOpen]);

  // Helper function to get dimension count
  const getDimensionCount = (column) => {
    if (!dimensions[column]) return 0;
    return dimensions[column].length;
  };

  const filtersCount = activeFiltersCount();

  return (
    <>
      {/* Drawer Trigger Button */}
      <div className="flex items-center align-center px-4 py-3 sm:px-6">
        <div className="flex items-center gap-2 ml-2">
          <button
            onClick={() => setIsDrawerOpen(true)}
            className={`flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 ${
              filtersCount === 0 ? 'border-2 border-gray-600' : ''
            }`}
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
            <span className="hidden sm:inline">Filtres</span> ({filtersCount})
          </button>

          {!isDefaultFilters && (
            <button
              onClick={handleResetFilters}
              className="text-sm text-blue-600 hover:text-blue-800 font-medium flex items-center gap-1"
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <circle cx="12" cy="12" r="9" fill="currentColor"/>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} stroke="white" d="M9 15L15 9M9 9l6 6"/>
              </svg>
              <span className="hidden md:inline">Réinitialiser</span>
            </button>
          )}

          <p className="text-sm text-gray-700 mr-4">
            <span className="font-medium">{paginationInfo.total.toLocaleString('fr-FR')}</span> résultat{paginationInfo.total > 1 ? 's' : ''}
          </p>

        </div>
      </div>

      {/* Drawer */}
      <div className={`fixed inset-0 z-40 ${isDrawerOpen ? '' : 'pointer-events-none'}`}>
        {/* Backdrop */}
        <div
          className={`fixed inset-0 bg-black ${isDrawerOpen ? 'opacity-25' : 'opacity-0'} transition-opacity duration-300`}
          onClick={() => setIsDrawerOpen(false)}
        ></div>

        {/* Drawer Panel */}
        <div className={`fixed inset-y-0 right-0 w-96 bg-white shadow-xl transform ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
          <div className="h-full flex flex-col">
            {/* Drawer Header */}
            <div className="px-4 py-6 bg-gray-50 border-b">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">Filtres</h2>
                <button
                  onClick={() => setIsDrawerOpen(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            {/* Drawer Content */}
            <div className="flex-1 overflow-y-auto p-4">
              <div className="space-y-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Période
                  </label>
                  <DateFilter
                    value={filters.createdat}
                    onChange={(dates) => handleFilterChange('createdat', dates)}
                  />
                </div>

                {allowedColumns.filter(column => column !== 'createdat').map((column, index, array) => {
                  // Check if this is one of the last 3 items
                  const isFirstThree = index < 3;

                  return (
                    <div key={column}>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {i18n[column] || column.charAt(0).toUpperCase() + column.slice(1)} ({getDimensionCount(column)})
                      </label>
                      <Select
                        key={`select-${column}-${filters[column]?.value || 'empty'}`}
                        value={filters[column] || null}
                        onChange={(selected) => handleFilterChange(column, selected)}
                        options={dimensions[column]}
                        isClearable
                        isMulti={['project', 'item'].includes(column)}
                        isLoading={!dimensions[column]}
                        menuPlacement={isFirstThree ? 'auto' : 'top'}
                        className="text-sm"
                        classNamePrefix="select"
                        placeholder="Sélectionner..."
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '36px',
                            height: 'auto'
                          }),
                          valueContainer: (base) => ({
                            ...base,
                            height: 'auto',
                            padding: '0 8px',
                            flexWrap: 'wrap'
                          }),
                          multiValue: (base) => ({
                            ...base,
                            margin: '2px',
                          }),
                          input: (base) => ({
                            ...base,
                            margin: '0px'
                          })
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Drawer Footer */}
            <div className="px-4 py-4 bg-gray-50 border-t">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={filters.favorite || false}
                  onChange={(e) => handleFilterChange('favorite', e.target.checked)}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer
                  peer-focus:ring-4 peer-focus:ring-red-300
                  dark:peer-focus:ring-red-800
                  peer-checked:after:translate-x-full
                  peer-checked:after:border-white
                  after:content-['']
                  after:absolute
                  after:top-0.5
                  after:left-[2px]
                  after:bg-white
                  after:border-gray-300
                  after:border
                  after:rounded-full
                  after:h-5
                  after:w-5
                  after:transition-all
                  peer-checked:bg-red-500"
                />
                <span className="ml-3 text-sm font-medium text-gray-700 flex items-center gap-1.5">
                  Afficher uniquement les favoris
                  <svg
                    className="w-4 h-4 text-red-500 fill-current"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="0"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}