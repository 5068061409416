import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { API_BASE_URL } from '../config';

const SectorSettingsModal = ({ isOpen, onClose, onSave, sector, sectors, selectedProject, fetchWithAuth }) => {
  const [usernames, setUsernames] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    sector: ''
  });

  const fetchUsernames = useCallback(async () => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/columns/username?project=${selectedProject}`
      );

      const values = await response.json();

      // For other columns, transform to react-select format
      setUsernames(values.map(value => ({ value, label: value })));

    } catch (error) {
      console.error('Error fetching dimensions:', error);
    }
  }, [fetchWithAuth, selectedProject]);

  useEffect(() => {
    // no need to fetch usernames if we're editing an existing sector
    if (sector)
      return;

    fetchUsernames();
  }, [fetchUsernames]);

  // Get existing sectors
  const existingSectors = sectors.map(q => q.username);

  // Create options for react-select, filtering out existing questions
  const usernameOptions = usernames
    .filter(q => !existingSectors.includes(q.value));

  useEffect(() => {
    if (sector) {
      setFormData({
        username: sector.username || '',
        sector: sector.sector || ''
      });
    } else {
      setFormData({
        username: '',
        sector: ''
      });
    }

    // Add event listener for Escape key
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [sector, onClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUsernameSelect = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      username: selectedOption.value,
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">
          {sector ? 'Edit Sector Assignment' : 'New Sector Assignment'}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Username
            </label>
            {sector ? (
              <input
                type="text"
                value={formData.username}
                className="w-full p-2 border rounded-md bg-gray-100"
                disabled
              />
            ) : (
              <Select
                value={formData.username ? { value: formData.username, label: formData.username } : null}
                onChange={handleUsernameSelect}
                options={usernameOptions}
                className="w-full"
                isSearchable
                placeholder="Select a username..."
                required
              />
            )}
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Sector
            </label>
            <input
              type="text"
              name="sector"
              value={formData.sector}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SectorSettingsModal;