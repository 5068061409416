import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config';

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const StatCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow p-6">
    <div className="flex items-center">
      <div className="p-3 rounded-full bg-blue-100">
        <Icon className="h-6 w-6 text-blue-600" />
      </div>
      <div className="ml-4">
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        <p className="text-2xl font-semibold text-blue-600">{formatNumber(value)}</p>
      </div>
    </div>
  </div>
);

// SVG Icons components
const UsersIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
);

const PhotoIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
  </svg>
);

const BuildingIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
  </svg>
);

const DocumentIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
  </svg>
);

const SkeletonStatCard = () => (
  <div className="bg-white rounded-lg shadow p-6">
    <div className="flex items-center">
      <div className="p-3 rounded-full bg-gray-200 animate-pulse">
        <div className="h-6 w-6" />
      </div>
      <div className="ml-4 flex-1">
        <div className="h-5 bg-gray-200 rounded w-24 mb-2 animate-pulse"></div>
        <div className="h-8 bg-gray-200 rounded w-16 animate-pulse"></div>
      </div>
    </div>
  </div>
);

export default function GlobalStatistics({ fetchWithAuth }) {
  const [stats, setStats] = useState(null);
  const [projectStats, setProjectStats] = useState(null);
  const [clientStats, setClientStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetchWithAuth(`${API_BASE_URL}/api/statistics/general`);
        if (response.error) throw new Error(response.error);
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    const fetchProjectStats = async () => {
      try {
        const response = await fetchWithAuth(`${API_BASE_URL}/api/statistics/countByProject`);
        if (response.error) throw new Error(response.error);
        const data = await response.json();
        setProjectStats(data);
      } catch (error) {
        console.error('Error fetching project statistics:', error);
      }
    };

    const fetchClientStats = async () => {
      try {
        const response = await fetchWithAuth(`${API_BASE_URL}/api/statistics/countByClient`);
        if (response.error) throw new Error(response.error);
        const data = await response.json();
        setClientStats(data);
      } catch (error) {
        console.error('Error fetching client statistics:', error);
      }
    };

    fetchStats();
    fetchProjectStats();
    fetchClientStats();
  }, [fetchWithAuth]);

  const statCards = [
    {
      title: 'Clients intégrés',
      value: stats?.clients || '--',
      icon: UsersIcon
    },
    {
      title: 'Projets',
      value: stats?.projects || '--',
      icon: BuildingIcon
    },
    {
      title: 'Utilisateurs',
      value: stats?.users || '--',
      icon: UsersIcon
    },
    {
      title: 'Formulaires',
      value: stats?.forms || '--',
      icon: DocumentIcon
    },
    {
      title: 'Photos',
      value: stats?.pictures || '--',
      icon: PhotoIcon
    },
    {
      title: 'Magasins',
      value: stats?.shops || '--',
      icon: BuildingIcon
    },
    {
      title: 'Questions',
      value: stats?.questions || '--',
      icon: DocumentIcon
    },
    {
      title: 'Items',
      value: stats?.items || '--',
      icon: DocumentIcon
    }
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Statistiques Globales</h2>
        {stats?.fresh_since && (
          <div className="text-sm text-gray-500">
            Données du {new Date(stats.fresh_since).toLocaleString('fr-FR')}
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {!stats ? (
          // Skeleton loading state
          [...Array(statCards.length)].map((_, index) => (
            <SkeletonStatCard key={index} />
          ))
        ) : (
          // Actual content
          statCards.map((card, index) => (
            <StatCard key={index} {...card} />
          ))
        )}
      </div>

      <div className="border-t border-gray-200 my-8"></div>

      <div className="flex gap-6">
        <div className="w-1/2">
          <h3 className="text-xl font-bold mb-4">Photos par client</h3>
          {!clientStats ? (
            <div className="animate-pulse bg-white rounded-lg shadow p-4">
              <div className="h-40 bg-gray-200 rounded"></div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Client
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Photos
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {clientStats.sort((a, b) => b.pictures - a.pictures).map((client, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {client.client}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatNumber(client.pictures)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="w-1/2">
          <h3 className="text-xl font-bold mb-4">Photos par projet</h3>
          {!projectStats ? (
            <div className="animate-pulse bg-white rounded-lg shadow p-4">
              <div className="h-40 bg-gray-200 rounded"></div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Projet
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Photos
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {projectStats.sort((a, b) => b.pictures - a.pictures).map((project, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {project.projectname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatNumber(project.pictures)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
